import React from "react";

import Markdown from "react-markdown";
import { LazyImage } from "../customs/lazyImage";

const Features = ({
  leftSectionTitle,
  leftSection,
  rightSectionTitle,
  rightSection,
  isMobileView,
}) => {
  return (
    <div className="serviceHotspot position-relative" id="overview">
      <div className="global-margin">
        <div className="row margin-top-botton">
          <div
            className={`col-md-6 px-2 ${
              isMobileView ? "col-12 text-center" : "pe-4"
            }`}
          >
            <h2 className="global-services-title roboto">{leftSectionTitle}</h2>
            <p className="global-services-desc ">
              <Markdown
                components={{
                  img: LazyImage,
                }}
              >
                {leftSection}
              </Markdown>
            </p>
          </div>
          <div
            className={`col-md-6 ${
              isMobileView ? "col-12 text-center" : "ps-4"
            }`}
          >
            <h2 className="global-services-title roboto">
              {rightSectionTitle}
            </h2>
            <p className="global-services-desc ">
              <Markdown
                components={{
                  img: LazyImage,
                }}
              >
                {rightSection}
              </Markdown>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
